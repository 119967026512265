import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, CircularProgress } from "@mui/material";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
      top: "50%",
      zIndex: "1050",
    },
  }),
  { index: 1 }
);

const CustomLoader = ({ size, fallback }) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        className={classes.root}
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ position: fallback ? "absolute" : "relative" }}
      >
        <CircularProgress
          // sx={{
          //   color:
          //     "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(169,39,124,1) 52%, rgba(24,56,135,1) 100%",
          // }}
          size="80"
        />
      </Grid>
    </>
  );
};

export default CustomLoader;
