import { createSlice } from "@reduxjs/toolkit";

const INIT_STATE = {
  currentActivity: null,
  result: {
    totalQuestions: 0,
    correct: 0,
    wrong: 0,
    attemptQuestions: [],
    selectedAnswers: [],
  },
};

const activitySlice = createSlice({
  name: "activity",
  initialState: INIT_STATE,
  reducers: {
    actionCurrentActivity: (state, action) => {
      state.currentActivity = action.payload;
      return state;
    },
    actionActivityResult: (state, action) => {
      state.result = action.payload;
      return state;
    },
    actionClearActivity: (state, action) => {
      return {
        currentActivity: null,
        result: {
          totalQuestions: 0,
          correct: 0,
          wrong: 0,
          attemptQuestions: [],
          selectedAnswers: [],
        },
      };
    },
  },
});

export const {
  actionActivityResult,
  actionCurrentActivity,
  actionClearActivity,
} = activitySlice.actions;

export default activitySlice.reducer;
