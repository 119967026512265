import { combineReducers } from "redux";
import user from "./slices/user.slice";
import student from "./slices/student.slice";
import book from "./slices/book.slice";
import activity from "./slices/activity.slice";

const rootReducer = combineReducers({
  user,
  student,
  book,
  activity,
});

export default rootReducer;
