import { lazy, memo } from "react";
// library
import { Navigate, useRoutes, Outlet } from "react-router-dom";
// constants
import { ROUTES_URL } from "src/constants/url.constant";

// layouts
const DashboardLayout = lazy(() => import("src/layouts/layout"));
const LogoOnlyLayout = lazy(() => import("src/layouts/LogoOnlyLayout"));
// public pages
const HomePage = lazy(() => import("src/pages/home/Home"));
const PrivacyPage = lazy(() => import("src/pages/home/privacy"));

const OkyLogin =lazy(() => import("src/pages/home/okyLogin"));
const NotFound = lazy(() => import("src/pages/Page404/Page404.page"));
const LogoutPage = lazy(() => import("src/pages/auth/Logout"));
// private pages
const Anasayfa = lazy(() => import("src/pages/dashboard/Anasayfa"));
const Benimduyam = lazy(() => import("src/pages/Benimduyam/Benimduyam"));
const Gorevlerim = lazy(() => import("src/pages/Gorevlerim/Gorevlerim"));
const Degerlendirmelerim = lazy(() => import("src/pages/Degerlendirmelerim/Degerlendirmelerim"));
const GorevlerimDetails = lazy(() =>
  import("src/components/bookDetails/GorevlerimDetails")
);

const DegerlendirmelerimDetails = lazy(() =>
  import("src/components/bookDetails/DegerlendirmelerimDetails")
);

const Oyunlarım = lazy(() => import("src/pages/myGames/MyGames"));
const Profilim = lazy(() => import("src/pages/Profilim/profilim"));
const Bookcase = lazy(() => import("src/pages/bookcase/Bookcase"));

const Activities = lazy(() => import("src/pages/activities/Activities"));

const Kitap_Etkinlikleri_Quiz = lazy(() =>
  import("src/pages/Kitap_Etkinlikleri_Quiz/Kitap_Etkinlikleri_Quiz")
);
const Book_Quiz_Result = lazy(() =>
  import("src/pages/Quiz_Result/Book_Quiz_Result")
);
const Pairing = lazy(() => import("src/pages/Pairing/Pairing"));
const Kitaplarım = lazy(() => import("src/pages/myBooks/MyBooks"));
// const Draganddrop = lazy(() => import("src/pages/dragDropActivity/DragDropActivity"));
const Draganddrop = lazy(() => import("src/pages/Book_Activities/Draganddrop"));
const VoiceActivity = lazy(() =>
  import("src/pages/voiceActivity/VoiceActivity")
);

const PuzzleActivity = lazy(() =>
  import("src/pages/puzzleActivity/PuzzleActivity")
);

// components
const PrivateRoute = lazy(() =>
  import("src/components/privateRoute/PrivateRoute")
);

const RoutesWrap = (props) => {
  const { t, i18n } = props;

  const appSplitStr = "/app/";

  return useRoutes([
    {
      path: ROUTES_URL.DASHBOARD,
      element: (
        <PrivateRoute>
          <DashboardLayout t={t} i18n={i18n} />
        </PrivateRoute>
      ),
      children: [
        // all private routes here

        { path: "", element: <Navigate to={ROUTES_URL.ANASAYFA} replace /> },
        {
          path: "",
          element: <Outlet />,
          children: [{ path: "", element: <Anasayfa t={t} i18n={i18n} /> }],
        },
        {
          path: ROUTES_URL.ANASAYFA.split(appSplitStr)[1],
          element: <Outlet />,
          children: [{ path: "", element: <Anasayfa t={t} i18n={i18n} /> }],
        },
        {
          path: ROUTES_URL.ANASAYFA.split(appSplitStr)[1],
          element: <Outlet />,
          children: [{ path: "", element: <Anasayfa t={t} i18n={i18n} /> }],
        },
        {
          path: ROUTES_URL.GOREVLERIM.split(appSplitStr)[1],
          element: <Outlet />,
          children: [{ path: "", element: <Gorevlerim t={t} i18n={i18n} /> }],
        },
        {
          path: `${ROUTES_URL.GOREVLERIM}/:taskId`,
          element: <Outlet />,
          children: [
            { path: "", element: <GorevlerimDetails t={t} i18n={i18n} /> },
          ],
        },
        {
          path: ROUTES_URL.DEGERLENDIRMELERIM.split(appSplitStr)[1],
          element: <Outlet />,
          children: [{ path: "", element: <Degerlendirmelerim t={t} i18n={i18n} /> }],
        },
        {
          path: `${ROUTES_URL.DEGERLENDIRMELERIM}/:taskId`,
          element: <Outlet />,
          children: [
            { path: "", element: <DegerlendirmelerimDetails t={t} i18n={i18n} /> },
          ],
        },
        {
          path: ROUTES_URL.BOOK_CASE.split(appSplitStr)[1],
          element: <Outlet />,
          children: [{ path: "", element: <Bookcase t={t} i18n={i18n} /> }],
        },
        {
          path: `${ROUTES_URL.KITAPLARIM}/:taskId`,
          element: <Outlet />,
          children: [{ path: "", element: <Kitaplarım t={t} i18n={i18n} /> }],
        },
        {
          path: `${ROUTES_URL.ACTIVITIES}/:taskId`,
          element: <Outlet />,
          children: [{ path: "", element: <Activities t={t} i18n={i18n} /> }],
        },
        {
          path: `${ROUTES_URL.KITAPQUIZ}/:taskId`,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Kitap_Etkinlikleri_Quiz t={t} i18n={i18n} />,
            },
          ],
        },
        {
          path: `${ROUTES_URL.BOOKQUIZRESULT}/:taskId`,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Book_Quiz_Result t={t} i18n={i18n} />,
            },
          ],
        },
        {
          path: `${ROUTES_URL.DRAGANDDROP}/:taskId`,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Draganddrop t={t} i18n={i18n} />,
            },
          ],
        },
        {
          path: `${ROUTES_URL.PAIRING}/:taskId`,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Pairing t={t} i18n={i18n} />,
            },
          ],
        },

        {
          path: `${ROUTES_URL.VOICE_TASK}/:taskId`,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <VoiceActivity t={t} i18n={i18n} />,
            },
          ],
        },
        {
          path: `${ROUTES_URL.PUZZLE}/:taskId`,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <PuzzleActivity t={t} i18n={i18n} />,
            },
          ],
        },
        {
          path: ROUTES_URL.BENIMDUYAM.split(appSplitStr)[1],
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <Benimduyam t={t} i18n={i18n} callFrom="ROUTES" />,
            },
          ],
        },

        {
          path: ROUTES_URL.OYUNLARIM.split(appSplitStr)[1],
          element: <Outlet />,
          children: [{ path: "", element: <Oyunlarım t={t} i18n={i18n} /> }],
        },
        {
          path: ROUTES_URL.PROFILIM.split(appSplitStr)[1],
          element: <Outlet />,
          children: [{ path: "", element: <Profilim t={t} i18n={i18n} /> }],
        },

        {
          path: ROUTES_URL.LOGOUT.split(appSplitStr)[1],
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <LogoutPage t={t} i18n={i18n} />,
            },
          ],
        },
      ],
    },

    // all public routes below

    {
      path: ROUTES_URL.HOME,
      element: <LogoOnlyLayout onlyChildren />,
      children: [
        {
          path: "/",
          element: <Outlet />,
          children: [{ path: "/", element: <HomePage t={t} i18n={i18n} /> }],
        },
        {
          path: "privacy",
          element: <Outlet />,
          children: [{ path: "/privacy", element: <PrivacyPage t={t} i18n={i18n} /> }],
        },
        {
          path: "okylogin",
          element: <Outlet />,
          children: [{ path: "/okylogin", element: <OkyLogin t={t} i18n={i18n} /> }],
        },
        {
          path: ROUTES_URL.NOT_FOUND.split("/")[1],
          element: <NotFound t={t} i18n={i18n} />,
        },
        {
          path: "/",
          element: <Navigate to={ROUTES_URL.BASE_PATH} />,
        },
        { path: "*", element: <Navigate to={ROUTES_URL.NOT_FOUND} /> },
      ],
    },

    { path: "*", element: <Navigate to={ROUTES_URL.NOT_FOUND} /> },
  ]);
};

export default memo(RoutesWrap);
