import React, { Suspense } from "react";
// npm
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
// routes
import RoutesWrap from "src/routes/Routes2";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import { Loader } from "./components";
// constants
import { ROUTES_URL } from "./constants/url.constant";

export default function App() {
  const { t, i18n } = useTranslation();

  return (
    <ThemeConfig>
      <Router basename={ROUTES_URL.BASE_PATH}>
        <GlobalStyles />
        <Suspense fallback={<Loader fallback={true} />}>
          <RoutesWrap t={t} i18n={i18n} />
        </Suspense>
      </Router>
    </ThemeConfig>
  );
}
